<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        بررسی نهایی و پرداخت
      </h2>
      <ul class="list-disc list-inside mt-3">
        <li class="text-red-500">
          خرید شما به معنی قبول شرایط و
          <router-link to="/" class="text-sky-600">قوانین سفارش</router-link>
          می‌باشد
        </li>
        <li>
          برای خواندن شرایط و قوانین
          <router-link to="/" class="text-sky-600">اینجا کلیک کنید</router-link>
        </li>
        <li>شارژ خریداری شده قابل برگشت نمیباشد لطفا دقت کنید</li>
      </ul>
    </template>
    <template #default>
      <div
        class="
          w-full
          md:w-8/12
          lg:w-6/12
          xl:w-5/12
          mx-auto
          bg-blueGray-50
          border border-coolGray-200
          rounded-md
          overflow-hidden
        "
      >
        <h2 class="text-lg font-bold text-center p-3 bg-sky-500 text-sky-50">
          سفارش : {{ pay.id }}
        </h2>
        <div>
          <div class="p-2 odd:bg-blueGray-100 text-center">
            <div class="font-bold">شارژ کیف پول</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">مقدار</div>
            <div class="font-bold text-left">{{ $number.format(pay.amount) }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">شارژ جایزه</div>
            <div class="font-bold text-left">{{ $number.format(bonus) }}</div>
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="flex w-full">
        <div class="flex-1 mx-1">
          <Button
            @click="payByAdmin"
            v-if="$store.state.user?.admin?.is_admin"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="green"
          >
            پرداخت {{ $number.format(pay.amount) }} تومان (ادمین)
          </Button>
          <Button
            v-else
            :href="apiUrl + 'api/go-pay/' + pay.token"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="green"
          >
            پرداخت {{ $number.format(pay.amount) }} تومان
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    Icon,
    Button,
    BodySimple,
  },
  data() {
    return {
      disabaledForm: false,
      errors: {},
      pay: {},
      apiUrl: process.env.VUE_APP_PAY_URL,
    };
  },
  computed: {
    bonus() {
      if (this.pay.amount >= 5000000) return (this.pay.amount * 20) / 100;
      else if (this.pay.amount >= 3000000) return (this.pay.amount * 15) / 100;
      else if (this.pay.amount >= 1000000) return (this.pay.amount * 10) / 100;
      else if (this.pay.amount >= 500000) return (this.pay.amount * 8) / 100;
      else return 0;
    },
  },
  mounted() {
    let $this = this;
    $this.errors = {};
    $this.disabaledForm = true;

    $this.$axios
      .get("/api/charges/" + $this.$route.params.payId, {
        params: { id: $this.$store.state.user.id },
      })
      .then(function (response) {
        $this.pay = response.data.pay;
        if (response.data.pay.status >= 100) {
          $this.$router.push({
            name: "WalletChargePayRes",
            params: { id: $this.$store.state.user.id, payId: response.data.pay.id },
          });
        }
      })
      .catch(function (error) {
        $this.errors = error.data;
        $this.disabaledForm = false;
        if (error.status == 406) {
          $this.$router.push("/panel");
        }
      });
  },
  methods: {
    payByAdmin() {
      let $this = this;
      $this.errors = {};
      $this.disabaledForm = true;
      $this.$axios
        .put("/api/charges/" + $this.$route.params.payId, {
          id: $this.$store.state.user.id,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push(response.data.goTo);
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
          if (error.status == 406) {
            $this.$router.push("/panel");
          }
        });
    },
  },
};
</script>
